<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Edit Form Service
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer"  tag="form" @submit.prevent="submitForm()" :class="{ 'card ': !isSubPage }" class=" ">
                                    <!--[form-content-start]-->
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Nama 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlnama" v-model.trim="formData.nama"  label="Nama" type="text" placeholder="Nama"    readonly  
                                                    class=" w-full" :class="getErrorClass('nama')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('nama')" class="p-error">{{ getFieldError('nama') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <input name="ctrlalamat"  ref="ctrlalamat" v-model="formData.alamat" type="hidden" />
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    No WA 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlno_wa" v-model.trim="formData.no_wa"  label="No WA" type="text" placeholder="No WA"    readonly  
                                                    class=" w-full" :class="getErrorClass('no_wa')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('no_wa')" class="p-error">{{ getFieldError('no_wa') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Merek 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlmerek" v-model.trim="formData.merek"  label="Merek" type="text" placeholder="Merek"      
                                                    class=" w-full" :class="getErrorClass('merek')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('merek')" class="p-error">{{ getFieldError('merek') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Tipe Perangkat 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Dropdown  class="w-full" :class="getErrorClass('tipe_perangkat')"      optionLabel="label" optionValue="value" ref="ctrltipe_perangkat"  v-model="formData.tipe_perangkat" :options="app.menus.tipePerangkatItems" label="Tipe Perangkat"  placeholder="Tipe Perangkat" >
                                                    </Dropdown> 
                                                    <small v-if="isFieldValid('tipe_perangkat')" class="p-error">{{ getFieldError('tipe_perangkat') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Serial Number 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlserial_number" v-model.trim="formData.serial_number"  label="Serial Number" type="text" placeholder="Serial Number"      
                                                    class=" w-full" :class="getErrorClass('serial_number')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('serial_number')" class="p-error">{{ getFieldError('serial_number') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Foto Barang 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <div class="mb-3">
                                                        <Uploader :class="getErrorClass('foto_barang')" :auto="true" :fileLimit="1" :maxFileSize="25000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" style="width:100%" label="Choose files or drop files here" upload-path="fileuploader/upload/foto_barang" v-model="formData.foto_barang"></Uploader>
                                                    </div>
                                                    <small v-if="isFieldValid('foto_barang')" class="p-error">{{ getFieldError('foto_barang') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Tanggal Booking 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Calendar  :showButtonBar="true" class="w-full" :class="getErrorClass('tanggal_booking')" dateFormat="yy-mm-dd" v-model="formData.tanggal_booking" :showIcon="true"     mask="YYYY-MM-DD"      />
                                                    <small v-if="isFieldValid('tanggal_booking')" class="p-error">{{ getFieldError('tanggal_booking') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Status 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <api-data-source   api-path="components_data/status_option_list" >
                                                        <template v-slot="req">
                                                            <Dropdown  class="w-full" :class="getErrorClass('status')"   :loading="req.loading"   optionLabel="label" optionValue="value" ref="ctrlstatus"  v-model="formData.status" :options="req.response" label="Status"  placeholder="Status Service" >
                                                            </Dropdown> 
                                                            <small v-if="isFieldValid('status')" class="p-error">{{ getFieldError('status') }}</small> 
                                                        </template>
                                                    </api-data-source>
                                                </div>
                                            </div>
                                        </div>
                                        <input name="ctrluser_id"  ref="ctrluser_id" v-model="formData.user_id" type="hidden" />
                                    </div>
                                    <!--[form-content-end]-->
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="loading">
            <div style="min-height:200px" class="flex gap-3 justify-content-center align-items-center p-3">
                <div><ProgressSpinner style="width:50px;height:50px" /> </div>
                <div class="text-500">Loading... </div>
            </div>
        </template>
    </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import { required } from 'src/services/validators';
	import { useApp } from 'src/composables/app.js';
	import { useAuth } from 'src/composables/auth';
	import { useEditPage } from 'src/composables/editpage.js';
	import { usePageStore } from 'src/store/page';
	const props = defineProps({
		id: [String, Number],
		pageStoreKey: {
			type: String,
			default: 'FORM_SERVICE',
		},
		pageName: {
			type: String,
			default: 'form_service',
		},
		routeName: {
			type: String,
			default: 'form_serviceedit',
		},
		pagePath: {
			type : String,
			default : 'form_service/edit',
		},
		apiPath: {
			type: String,
			default: 'form_service/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: "Update Record",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		msgAfterSave: {
			type: String,
			default: "Data berhasil diupdate",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	
	const store = usePageStore(props.pageStoreKey);
	const app = useApp();
	const auth = useAuth();
	
	const formDefaultValues = Object.assign({
		nama: auth.user.nama, 
		alamat: auth.userId, 
		no_wa: auth.user.no_wa, 
		merek: "", 
		tipe_perangkat: "", 
		serial_number: "", 
		foto_barang: "", 
		tanggal_booking: new Date(), 
		status: "", 
		user_id: auth.userId, 
	}, props.pageData);
	
	const formData = reactive({ ...formDefaultValues });
	
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigateTo(`/form_service`);
		}
	}
	
	// form validation rules
	const rules = computed(() => {
		return {
			nama: {  },
			alamat: {  },
			no_wa: {  },
			merek: {  },
			tipe_perangkat: {  },
			serial_number: {  },
			foto_barang: {  },
			tanggal_booking: {  },
			status: {  },
			user_id: {  }
		}
	});
	
	const page = useEditPage({store, props, formData, rules, afterSubmit });
	
	const {  currentRecord: editRecord } = toRefs(store.state);
	const {  pageReady, saving, loading, } = toRefs(page.state);
	
	const { apiUrl } = page.computedProps;
	
	const { load, submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
	
	onMounted(()=>{
		const pageTitle = "Form Service";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
</script>
<style scoped>
</style>
